import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import { useDispatch } from "react-redux";
import { updateInitialProfile } from "../../../services/profile";
import { setLoading } from "../../../reducer/auth/auth";
import Select from "react-select";
import { TagsInput } from "react-tag-input-component";
import {
  capatilizeLetter,
  filterVisibleTimeZones,
  filterZone,
} from "../../../utils/helpers";
import PhoneInputField from "../../../components/Inputfields/phoneinput";
import moment from "moment-timezone";
import TimeZoneCofirmPopup from "../timeZoneConfirmPopup";
import TimeZonePopup from "../timeZonePopup";
const StepOne = (props) => {
  const [newformData, setNewFormData] = useState([]);
  const [newSpecilityData, setNewSpecilityData] = useState([]);
  const [newCountryData, setNewCountryData] = useState([]);
  const [newTimezoneData, setNewTimezoneData] = useState([]);
  const [openOtherLnaguages, setOpenOtherLnaguages] = useState(false);
  const [openOtherSpecility, setOpenOtherSpecility] = useState(false);
  const [userCountryCode, setUserCountryCode] = useState("US");
  const [timeZoneError, setTimeZoneError] = useState(false);
  const [temporaryStorage, setTemporaryStorage] = useState({});
  const [disableTime ,setDisableTime ] = useState('');
  const {
    NextStep,
    stepOneData,
    stepTwoData,
    setValues,
    formLanguages,
    specialtyData,
    countryData,
    countryOnly,
    timezoneData,
    timezoneOnly,
    showTimeZoneError
  } = props;

  const [CurrentDate, setCurrentDate] = useState(moment().format('hh:mm A'))
  const dispatch = useDispatch();

  useEffect(() => {
    // window.loadModal2('#TimeZonePopup', 'show');
    setNewFormData(
      formLanguages.map((item) => {
        return {
          ...item,
          value: item.id,
          label: item.name,
        };
      })
    );
    setNewTimezoneData(filterVisibleTimeZones(timezoneData));
    setNewSpecilityData(
      specialtyData.map((item) => {
        return {
          ...item,
          value: item.id,
          label: item.specialty_name,
        };
      })
    );
    setNewCountryData(
      countryData.map((item) => {
        return {
          ...item,
          value: item.id,
          label: item.country_name,
        };
      })
    );
    addDefault();
  }, ["", formLanguages, specialtyData, countryData, timezoneData]);

  useEffect(() => {
    setNewSpecilityData(
      specialtyData.map((item) => {
        return {
          ...item,
          value: item.id,
          label: item.specialty_name,
        };
      })
    );
    addDefault();
  }, [specialtyData]);

  const addDefault = () => {
    if (!stepOneData.languages.length > 0 && newformData.length) {
      stepOneData.languages.push(newformData[10]);
    }
    if (
      !stepOneData.mediumshipSpecialities.length > 0 &&
      newSpecilityData.length
    ) {
      stepOneData.mediumshipSpecialities.push(newSpecilityData[0]);
    }
  };
  const RunFunction = (formData) => {
    setValues(formData, 1);
    const {
      firstName,
      lastName,
      phone,
      whatsapp,
      languages,
      mediumshipLevel,
      mediumshipSpecialities,
      country,
      website,
      biographicalInfo,
      otherLanguages,
      otherMediumSpecialities,
      timezone,
      medium_professional_category,
    } = formData;

    // if (timezone.id === "" && timezone.name === "") {

    //   dispatch(setLoading(false));
    //   setTimeZoneError(true)
    //   return;

    // }

    let lang = languages.map((item) => {
      return item.id;
    });


    let timezones = timezone.id === "" ? filterZone(timezone, timezoneData) : timezone.id;
    let speci = mediumshipSpecialities.map((item) => {
      return item.id;
    });
    let item = {
      first_name: firstName,
      last_name: lastName,
      phone: phone,
      whatsapp: whatsapp == "whatsapp" ? "" : whatsapp,
      website: website,
      country: country.label !== undefined ? country.label : country,
      languages: lang,
      mediumship_specialties: speci,
      current_mediumship_level: parseInt(mediumshipLevel),
      biographical_info: biographicalInfo,
      other_languages: otherLanguages,
      other_mediumship_specialties: otherMediumSpecialities,
      timezone: timezones,
      medium_professional_category: medium_professional_category,
    };

    if (timezone.name == "US/Arizona") {
      setDisableTime(timezone.name)
      window.loadModal2('#TimeZoneCofirmPopup', 'show');
      setTemporaryStorage(item);
      return;
    }
    if (timezone.name === "US/Mountain" || timezone.name === "America/Los_Angeles") {
      setTemporaryStorage(item);
      const check = phone.slice(0, 4);
      console.log(check)
      let array = [1623, 1480, 1520, 1867]
      if (array.includes(parseInt(check))) {
        setDisableTime('')
        window.loadModal2('#TimeZoneCofirmPopup', 'show');
        return;
      }
      else{
        window.loadModal2('#TimeZonePopup', 'show');
        return;
      }

    }
    setDisableTime('')
    dispatch(setLoading(true));

    updateInitialProfile(item)
      .then((data) => {
        dispatch(setLoading(false));
        if (data.data.status) {
          stepTwoData.screenName = `${firstName} ${lastName.substring(0, 1)}`;
          NextStep();
        }
      })
      .catch((err) => {
        dispatch(setLoading(false));
        console.log(err);
      });
  };
  //
  const confirmRequest = (values) => {
    let value = values == "" ? temporaryStorage : values;
    window.loadModal2('#TimeZonePopup', 'hide');
    console.log(value);
    dispatch(setLoading(true));
    updateInitialProfile(value)
      .then((data) => {
        dispatch(setLoading(false));
        if (data.data.status) {
          stepTwoData.screenName = `${temporaryStorage.first_name} ${temporaryStorage.last_name.substring(0, 1)}`;
        
          NextStep();
        }
      })
      .catch((err) => {
        dispatch(setLoading(false));
        console.log(err);
      });
  }
  const cancelRequest = () => {
    window.loadModal2('#TimeZoneCofirmPopup', 'hide');
    window.loadModal2('#TimeZonePopup', 'show');
  }

  const confirmNewTimeZone = (value) => {
    console.log(temporaryStorage, "temporaryStorage", value);

    let newValue = {
      ...temporaryStorage,
      timezone: parseInt(value),
    }
    console.log(newValue, newValue)
    confirmRequest(newValue)
  }
  //

  const customHandleChange = (errors) => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const capatilizeLetters = (e, name, setFieldValue) => {
    let values = e.target.value;
    const input = capatilizeLetter(values);
    setFieldValue(name, input);
  };
  const countryChangeHandler = (value, name, setFieldValue) => {
    setUserCountryCode(value.country_code);
    setFieldValue(name, value);
  };
  // const checkTat = () => {
  //   console.log(timezoneData, "timezoneData")
  //   let timezone = stepOneData.timezone;
  //   let check = timezoneData.filter((time) => time.name === timezone.name);
  //   if (check.length) {
  //     return
  //   } else {
  //     stepOneData.timezone = { id: '', name: '' }
  //     // setTimeZoneError(true)
  //   }


  // }
  const changeTimeZone = (setFieldValue, value) => {
    setFieldValue("timezone", value)
    setCurrentDate(moment().tz(value.name).format('hh:mm A'))
  }
  return (
    <>
      <Formik
        initialValues={stepOneData}
        validate={(values) => {
          console.log(values, "valies")
          const errors = {};
          if (!values.firstName) errors.firstName = "Required";
          if (!values.languages.length) errors.languages = "Required";
          if (!values.mediumshipSpecialities.length)
            errors.mediumshipSpecialities = "Required";
          if (!values.timeconfirmcheck) errors.timeconfirmcheck = "Required";
          if (!values.lastName) errors.lastName = "Required";
          if (!values.phone) errors.phone = "Required";
          // if (values.phone && !/^[0-9]+$/.test(values.phone)) {
          //   errors.phone = "Invalid Phone";
          // }
          // if (values.whatsapp && values.whatsapp.length < 10) {
          //   errors.whatsapp = "Invalid whatsapp";
          // }
          if (values.phone && values.phone.length < 10) {
            errors.phone = "Invalid Phone";
          }
          // if (values.phone && values.phone.length) {
          //   errors.phone = "Invalid Phone";
          // }
          // if (values.whatsapp && !/^[0-9]+$/.test(values.whatsapp)) {
          //   errors.whatsapp = "Invalid whatsapp";
          // }
          // if (!values.whatsapp) errors.whatsapp = "Required";
          if (!values.country) errors.country = "Required";
          // if (!values.website) errors.website = "Required";
          if (!values.mediumshipLevel) errors.mediumshipLevel = "Required";
          // if (!values.biographicalInfo) errors.biographicalInfo = "Required";
          if (values.timezone.id === "" || values.timezone == "") errors.timezone = "Required";
          console.log(values.timezone)
          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          RunFunction(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          setFieldValue,
          handleSubmit,
          isSubmitting,
          setFieldTouched,
        }) => (
          <form onSubmit={handleSubmit}>
            {addDefault()}
            {/* {checkTat()} */}
            <div className="from-inner-box">
              <div className="left-side">
                <label>
                  Legal First Name<i>*</i>
                </label>
                <input
                  onChange={(e) =>
                    capatilizeLetters(e, "firstName", setFieldValue)
                  }
                  className={`${errors.firstName && touched.firstName && errors.firstName
                    ? "error-boundry"
                    : ""
                    }`}
                  onBlur={handleBlur}
                  value={values.firstName}
                  type="text"
                  id="firstName"
                  name="firstName"
                  placeholder="First Name"
                />
              </div>
              <div className="right-side">
                <label>
                  Legal Last Name<i>*</i>
                </label>
                <input
                  className={`${errors.lastName && touched.lastName && errors.lastName
                    ? "error-boundry"
                    : ""
                    }`}
                  onChange={(e) =>
                    capatilizeLetters(e, "lastName", setFieldValue)
                  }
                  onBlur={handleBlur}
                  value={values.lastName}
                  type="text"
                  id="lastName"
                  name="lastName"
                  placeholder="Last Name"
                />
              </div>
            </div>
            <div className="from-inner-box cuntrey-website">
              <div className="">
                <label>
                  Country <i>*</i>
                </label>
                {countryOnly && (
                  <Select
                    id="country"
                    className="selection-box"
                    options={newCountryData}
                    defaultInputValue={
                      typeof values.country == "object"
                        ? values.country.country_name
                        : values.country
                    }
                    onChange={(value) =>
                      countryChangeHandler(value, "country", setFieldValue)
                    }
                    onBlur={handleBlur}
                    placeholder="Country*"
                    value={values.country}
                    closeMenuOnSelect={true}
                    closeMenuOnScroll={true}
                  />
                )}
                {countryOnly == null && (
                  <Select
                    id="country"
                    className="selection-box"
                    options={newCountryData}
                    defaultInputValue={
                      typeof values.country == "object"
                        ? values.country.country_name
                        : values.country
                    }
                    onChange={(value) =>
                      countryChangeHandler(value, "country", setFieldValue)
                    }
                    onBlur={handleBlur}
                    placeholder="Country*"
                    value={values.country}
                    closeMenuOnSelect={true}
                    closeMenuOnScroll={true}
                  />
                )}

                <span className="error text-left">
                  {errors.country && touched.country && errors.country}
                </span>
              </div>
            </div>

            <div className="from-inner-box">
              <div className="left-side">
                <label>
                  Phone<i>*</i>

                </label>
                <PhoneInputField
                  className={`number-input ${errors.phone && touched.phone && errors.phone
                    ? "error-boundry"
                    : ""
                    }`}
                  country={userCountryCode.toLowerCase()}
                  setFieldValue={setFieldValue}
                  onBlur={handleBlur}
                  value={values.phone}
                  id="phone"
                  name="phone"
                />
                {/* <input
                className={`${
                  errors.phone && touched.phone && errors.phone
                    ? "error-boundry"
                    : ""
                }`}
                onChange={(e) => handleChangeHandler(e, "phone", setFieldValue)}
                onBlur={handleBlur}
                value={values.phone}
                maxlength="10"
                pattern="\d{10}"
                id="phone"
                name="phone"
                type="tel"
              /> */}
                <span className="error">
                  {errors.phone && touched.phone && errors.phone}
                </span>
              </div>
              <div className="right-side">
                <label>Whatsapp</label>
                <PhoneInputField
                  className={`number-input ${errors.whatsapp && touched.whatsapp && errors.whatsapp
                    ? "error-boundry"
                    : ""
                    }`}
                  country={userCountryCode.toLowerCase()}
                  setFieldValue={setFieldValue}
                  onBlur={handleBlur}
                  value={values.whatsapp}
                  id="whatsapp"
                  name="whatsapp"
                />
                {/* <input
                className={`${
                  errors.whatsapp && touched.whatsapp && errors.whatsapp
                    ? "error-boundry"
                    : ""
                }`}
                onChange={(e) =>
                  handleChangeHandler(e, "whatsapp", setFieldValue)
                }
                onBlur={handleBlur}
                value={values.whatsapp}
                maxlength="10"
                pattern="\d{10}"
                id="whatsapp"
                name="whatsapp"
                placeholder=""
                type="tel"
              /> */}
                <span className="error">
                  {errors.whatsapp && touched.whatsapp && errors.whatsapp}
                </span>
              </div>
            </div>
            <div className="from-inner-box">
              <div className="full-fild">
                <label>
                  Languages Available for Mediumship Sessions<i>*</i>
                </label>
                <Select
                  id="languages"
                  className="selection-box"
                  options={newformData}
                  multi={true}
                  isMulti
                  onChange={(value) => setFieldValue("languages", value)}
                  onBlur={handleBlur}
                  placeholder="Select languages"
                  value={values.languages}
                  closeMenuOnSelect={false}
                  closeMenuOnScroll={false}
                />
                <span
                  onClick={() => setOpenOtherLnaguages(!openOtherLnaguages)}
                  className="other-lang pointer"
                >
                  Other Language
                </span>
                {openOtherLnaguages && (
                  <TagsInput
                    value={values.otherLanguages}
                    className="form-other-tags-input"
                    name="otherLanguages"
                    validationRegex={/^[a-zA-Z,]+$/}
                    onChange={(value) => setFieldValue("otherLanguages", value)}
                    placeHolder="Other Language"
                  />
                )}
                <span className="error text-left">
                  {errors.languages && touched.languages && errors.languages}
                </span>
              </div>
            </div>
            <div className="from-inner-box radeo">
              <div className="full-fild">
                <label>
                  Current Mediumship Level <i>*</i>
                </label>
                <div className="InputGroup">
                  <div className="radio-box">
                    <input
                      onChange={handleChange}
                      onBlur={handleBlur}
                      type="radio"
                      name="mediumshipLevel"
                      id="size_1"
                      value={1}
                      checked={
                        parseInt(values.mediumshipLevel) === 1 ? true : false
                      }
                    />
                    <label htmlFor="size_1">Novice</label>
                  </div>
                  <div className="radio-box">
                    <input
                      onChange={handleChange}
                      onBlur={handleBlur}
                      type="radio"
                      name="mediumshipLevel"
                      id="size_2"
                      value={2}
                      checked={
                        parseInt(values.mediumshipLevel) === 2 ? true : false
                      }
                    />
                    <label htmlFor="size_2">Intermediate</label>
                  </div>
                  <div className="radio-box">
                    <input
                      onChange={handleChange}
                      onBlur={handleBlur}
                      type="radio"
                      name="mediumshipLevel"
                      id="size_3"
                      value={3}
                      checked={
                        parseInt(values.mediumshipLevel) === 3 ? true : false
                      }
                    />
                    <label htmlFor="size_3">Professional</label>
                  </div>
                </div>
                <span className="error text-left">
                  {errors.mediumshipLevel &&
                    touched.mediumshipLevel &&
                    errors.mediumshipLevel}
                </span>
              </div>
            </div>
            {/***if professinal */}
            {parseInt(values.mediumshipLevel) === 3 && (
              <div className="from-inner-box radeo">
                <div className="full-fild">
                  <label>
                    Years as professional <i>*</i>
                  </label>
                  <div className="InputGroup">
                    <div className="radio-box">
                      <input
                        onChange={handleChange}
                        onBlur={handleBlur}
                        type="radio"
                        name="medium_professional_category"
                        id="medium_professional_category_1"
                        value={1}
                        checked={
                          parseInt(values.medium_professional_category) === 1
                            ? true
                            : false
                        }
                      />
                      <label htmlFor="medium_professional_category_1">
                        1 year
                      </label>
                    </div>
                    <div className="radio-box">
                      <input
                        onChange={handleChange}
                        onBlur={handleBlur}
                        type="radio"
                        name="medium_professional_category"
                        id="medium_professional_category_2"
                        value={2}
                        checked={
                          parseInt(values.medium_professional_category) === 2
                            ? true
                            : false
                        }
                      />
                      <label htmlFor="medium_professional_category_2">
                        2-3 years
                      </label>
                    </div>
                    <div className="radio-box">
                      <input
                        onChange={handleChange}
                        onBlur={handleBlur}
                        type="radio"
                        name="medium_professional_category"
                        id="medium_professional_category_3"
                        value={3}
                        checked={
                          parseInt(values.medium_professional_category) === 3
                            ? true
                            : false
                        }
                      />
                      <label htmlFor="medium_professional_category_3">
                        3-5 years
                      </label>
                    </div>
                    <div className="radio-box">
                      <input
                        onChange={handleChange}
                        onBlur={handleBlur}
                        type="radio"
                        name="medium_professional_category"
                        id="medium_professional_category_4"
                        value={4}
                        checked={
                          parseInt(values.medium_professional_category) === 4
                            ? true
                            : false
                        }
                      />
                      <label htmlFor="medium_professional_category_4">
                        5-10 years
                      </label>
                    </div>
                    <div className="radio-box">
                      <input
                        onChange={handleChange}
                        onBlur={handleBlur}
                        type="radio"
                        name="medium_professional_category"
                        id="medium_professional_category_5"
                        value={5}
                        checked={
                          parseInt(values.medium_professional_category) === 5
                            ? true
                            : false
                        }
                      />
                      <label htmlFor="medium_professional_category_5">
                        10-15 years
                      </label>
                    </div>
                    <div className="radio-box mt-2">
                      <input
                        onChange={handleChange}
                        onBlur={handleBlur}
                        type="radio"
                        name="medium_professional_category"
                        id="medium_professional_category_6"
                        value={6}
                        checked={
                          parseInt(values.medium_professional_category) === 6
                            ? true
                            : false
                        }
                      />
                      <label htmlFor="medium_professional_category_6">
                        15+ years
                      </label>
                    </div>
                  </div>
                  <span className="error text-left">
                    {errors.mediumshipLevel &&
                      touched.mediumshipLevel &&
                      errors.mediumshipLevel}
                  </span>
                </div>
              </div>
            )}
            <div className="from-inner-box">
              <div className="full-fild">
                <label>
                  Mediumship Specialities <i>*</i>
                </label>
                <Select
                  id="mediumshipSpecialities"
                  className="selection-box"
                  options={newSpecilityData}
                  multi={true}
                  isMulti
                  onChange={(value) =>
                    setFieldValue("mediumshipSpecialities", value)
                  }
                  onBlur={handleBlur}
                  placeholder="Select Specialities"
                  value={values.mediumshipSpecialities}
                  closeMenuOnSelect={false}
                  closeMenuOnScroll={false}
                />

                <span
                  onClick={() => setOpenOtherSpecility(!openOtherSpecility)}
                  className="other-lang pointer"
                >
                  Other specialities
                </span>
                {openOtherSpecility && (
                  <TagsInput
                    value={values.otherMediumSpecialities}
                    className="form-other-tags-input"
                    onChange={(value) =>
                      setFieldValue("otherMediumSpecialities", value)
                    }
                    name="otherSpecialities"
                    placeHolder="Other specialities"
                  />
                )}
                <span className="error text-left">
                  {errors.mediumshipSpecialities &&
                    touched.mediumshipSpecialities &&
                    errors.mediumshipSpecialities}
                </span>
              </div>
            </div>
            <div className="from-inner-box cuntrey-website">
              <div className="left-side cuntrey-website">
                <label>
                  Timezone<i>*</i>
                </label>

                {timezoneOnly && (
                  <Select
                    id="timezone"
                    className="selection-box"
                    options={newTimezoneData}
                    defaultValue={values.timezone}
                    defaultInputValue={values.timezone.front_name}
                    onChange={(value) => changeTimeZone(setFieldValue, value)}
                    onBlur={handleBlur}
                    placeholder="Select Your Timezone"
                    value={values.timezone}
                    closeMenuOnSelect={true}
                    closeMenuOnScroll={true}
                  />
                )}
                {timezoneOnly === null && (
                  <Select
                    id="timezone"
                    className="selection-box"
                    options={newTimezoneData}
                    defaultValue={values.timezone}
                    defaultInputValue={values.timezone.front_name}
                    onChange={(value) => changeTimeZone(setFieldValue, value)}
                    onBlur={handleBlur}
                    placeholder="Select Your Timezone"
                    value={values.timezone}
                    closeMenuOnSelect={true}
                    closeMenuOnScroll={true}
                  />
                )}
                <span className="error mt-1">
                  {errors.timezone && touched.timezone && errors.timezone}

                </span>
                <h6 className="mt-3">   Current time as per selected timezone: <b className="red font-size-18">{CurrentDate}</b></h6>
                <div className="full-fild">

                  <div className="prvacy-box eth-pri">
                    <input
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value="The time above is my correct time"
                      id="timeconfirmcheck"
                      type="checkbox"
                      name="timeconfirmcheck"
                      defaultChecked={
                        values.timeconfirmcheck ===
                          "The time above is my correct time"
                          ? true
                          : false
                      }
                      className="pointer"
                    />
                    <label htmlFor="timeconfirmcheck">
                      The time above is my correct time.
                    </label>
                    <span className="error">
                      {errors.timeconfirmcheck &&
                        touched.timeconfirmcheck &&
                        errors.timeconfirmcheck}
                      {/* {!values.timeconfirmcheck && showRequiredError ? 'Required' : ''} */}
                    </span>
                  </div>
                </div>

              </div>
              {parseInt(values.mediumshipLevel) === 3 && (
                <div className="right-side cuntrey-website">
                  <label>Website</label>
                  <input
                    className={`${errors.website && touched.website && errors.website
                      ? "error-boundry"
                      : ""
                      }`}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.website}
                    id="website"
                    name="website"
                    type="text"
                    placeholder="yourwebsite.com"
                  />
                </div>
              )}
            </div>

            {/* {parseInt(values.mediumshipLevel) === 3 && (
            <div className="from-inner-box last-nput">
              <label>Biographical Info</label>
              <textarea
                className={`${
                  errors.biographicalInfo &&
                  touched.biographicalInfo &&
                  errors.biographicalInfo
                    ? "error-boundry"
                    : ""
                }`}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.biographicalInfo}
                //   style="text"
                id="biographicalInfo"
                name="biographicalInfo"
                placeholder="Write Here..."
                rows="4"
                cols="50"
              ></textarea>
            </div>
          )} */}
            <button
              onClick={() => customHandleChange(errors)}
              type="submit"
              className="next action-button"
            // value="Next"
            >
              Next
            </button>
          </form>
        )}
      </Formik>
      <TimeZoneCofirmPopup confirmRequest={confirmRequest} cancelRequest={cancelRequest} id={"TimeZoneCofirmPopup"} />
      <TimeZonePopup newTimezoneData={newTimezoneData} confirmNewTimeZone={confirmNewTimeZone} disableTime={disableTime} CurrentDate={CurrentDate} />
    </>

  );
};
export default StepOne;
