import React from "react";
import "./backfill.css";

export default function BackFill() {
    return (
        <div className="backfill-wrapper">
            <div className="backfill-container">
                <div className="backfill-left-side ">
                    <div className="d-flex margin-60px flex-wrap ">
                        <h4 className="width-260-px">Triggering Services</h4>{" "}
                        <div>
                            <div className="prvacy-box">
                                <input
                                    defaultChecked={true}
                                    // onChange={handleChange}
                                    // onBlur={handleBlur}
                                    value="Donated"
                                    id="Donated"
                                    type="checkbox"
                                    name="Donated"
                                // disabled
                                // checked={values.agree_to_legal_terms_and_conditionss ? 'checked' : ''}
                                />

                                <label htmlFor="Donated" className="width-unset">
                                    Donated<i>*</i>
                                </label>
                            </div>
                            <div className="prvacy-box">
                                <input
                                    // onChange={handleChange}
                                    // onBlur={handleBlur}
                                    value="Paid"
                                    id="Paid"
                                    type="checkbox"
                                    name="agreePaid_to_legal_terms_and_conditionss"
                                // disabled
                                // checked={values.agree_to_legal_terms_and_conditionss ? 'checked' : ''}
                                />

                                <label htmlFor="Paid" className="width-unset">
                                    Paid
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex margin-60px flex-wrap ">
                        <h4 className="width-260-px">Appointment Times </h4>{" "}
                        <div>
                            <div className="prvacy-box">
                                <input
                                    // onChange={handleChange}
                                    // onBlur={handleBlur}
                                    value="Exact match only"
                                    id="Exact-match-only"
                                    type="radio"
                                    name="Appointment-Times"
                                // disabled
                                // checked={values.agree_to_legal_terms_and_conditionss ? 'checked' : ''}
                                />

                                <label htmlFor="Exact-match-only" className="width-unset">
                                    Exact match only
                                </label>
                            </div>
                            <div className="prvacy-box">
                                <input
                                    // onChange={handleChange}
                                    // onBlur={handleBlur}
                                    value="Any that fit donated schedule"
                                    id="Any-that-fit-donated-schedule"
                                    type="radio"
                                    name="Appointment-Times"
                                // disabled
                                // checked={values.agree_to_legal_terms_and_conditionss ? 'checked' : ''}
                                />

                                <label htmlFor="Any-that-fit-donated-schedule" className="width-unset">
                                    Any that fit donated schedule<i>*</i>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="backfill-right-side ">
                    <div className="d-flex margin-60px flex-wrap ">
                        <h4 className="width-260-px">Automatic</h4>{" "}
                        <div>
                            <div className="prvacy-box">
                                <input
                                    // onChange={handleChange}
                                    // onBlur={handleBlur}
                                    value="AutomaticYes"
                                    id="AutomaticYes"
                                    type="radio"
                                    name="Automatic"
                                // disabled
                                // checked={values.agree_to_legal_terms_and_conditionss ? 'checked' : ''}
                                />

                                <label htmlFor="AutomaticYes" className="width-unset">
                                    Yes
                                </label>
                            </div>
                            <div className="prvacy-box">
                                <input
                                defaultChecked={true}
                                    // onChange={handleChange}
                                    // onBlur={handleBlur}
                                    value="AutomaticNo"
                                    id="AutomaticNo"
                                    type="radio"
                                    name="Automatic"
                                // disabled
                                // checked={values.agree_to_legal_terms_and_conditionss ? 'checked' : ''}
                                />

                                <label htmlFor="AutomaticNo" className="width-unset">
                                    No<i>*</i>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex margin-60px flex-wrap ">
                        <h4 className="width-260-px">Notification Method </h4>{" "}
                        <div>
                            <div className="prvacy-box">
                                <input
                                    // onChange={handleChange}
                                    // onBlur={handleBlur}
                                    value="Email Only"
                                    id="Email-Only-1"
                                    type="radio"
                                    name="Email-Only"
                                // disabled
                                // checked={values.agree_to_legal_terms_and_conditionss ? 'checked' : ''}
                                />

                                <label htmlFor="Email-Only-1" className="width-unset">
                                    Email Only
                                </label>
                            </div>
                            <div className="prvacy-box">
                                <input
                                    // onChange={handleChange}
                                    // onBlur={handleBlur}
                                    value="Email & Text (U.S. & CAN only)"
                                    id="Email-Only-2"
                                    type="radio"
                                    name="Email-Only"
                                // disabled
                                // checked={values.agree_to_legal_terms_and_conditionss ? 'checked' : ''}
                                />

                                <label htmlFor="Email-Only-2" className="width-unset">
                                    Email & Text (U.S. & CAN only)<i>*</i>
                                </label>
                            </div>
                            <div className="prvacy-box">
                                <input
                                    // onChange={handleChange}
                                    // onBlur={handleBlur}
                                    value="Email & WhatsApp (all countries)"
                                    id="Email-Only-3"
                                    type="radio"
                                    name="Email-Only"
                                // disabled
                                // checked={values.agree_to_legal_terms_and_conditionss ? 'checked' : ''}
                                />

                                <label htmlFor="Email-Only-3" className="width-unset">
                                    Email & WhatsApp (all countries)<i>*</i>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
