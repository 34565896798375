import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import moment from "moment-timezone";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import { getTimezones } from "../../services/uidata";
import { filterVisibleTimeZones } from "../../utils/helpers";

//css
import "./checktimezone.css";
import { updateNewTimeZone } from "../../services/profile";
import { setUser } from "../../reducer/userSlice";
import { fetchUser } from "../../services/auth";
import { ar } from "date-fns/locale";

export default function TimeZonePopup({ newTimezoneData = [], confirmNewTimeZone,disableTime }) {
    //states
    // const navigate = useNavigate();
    const dispatch = useDispatch()

    const [value, setValue] = useState("");
    const [error, setError] = useState(false);



    const changeHandler = (e) => {
        setError(false)
        setValue(e.target.value);
    };

    const submitHandler = () => {
        if (value !== "") {
            confirmNewTimeZone(value)
        }
        else {
            setError(true)
        }
    }


    return (
        <div
            className="modal fade mt-5"
            id="TimeZonePopup"
            data-backdrop="static"
            role="dialog"
        >
            <div className="modal-dialog  modal-dialog-preview">
                <div className="modal-content">
                    <div className="modal-body">
                        <div className="schedule-popup">
                            {/* <img src="../images/new-cll.png" alt="user" /> */}

                            <h3 className="">
                                What timezone do you think you are in?
                                <br />
                            </h3>
                            <div>
                                <div className="cuntrey-website p-5">
                                    <div className="cuntrey-website">
                                        {/* {timezoneOnly && ( */}
                                        <select
                                            className="select-check"
                                            value={value}
                                            onChange={changeHandler}
                                            id="timezone"
                                            name="timezone"
                                            placeholder="Select Your Timezone"
                                        >
                                            <option disabled="" selected="" value="">
                                                Timezone<i>*</i>
                                            </option>
                                            {filterVisibleTimeZones(newTimezoneData).map((timezone) => (
                                                <option disabled={disableTime == timezone.name} value={timezone.id}>
                                                    {timezone.front_name}
                                                </option>
                                            ))}
                                        </select>

                                        {error && <div className="error-select"> <span className="error">Required</span></div>}
                                        {/* )} */}

                                        {/* <span className="error">
                {timeZoneError && 'Required'}
              </span> */}
                                    </div>
                                </div>
                            </div>
                            <div
                                className="advance-tag margin-unset pointer"
                                onClick={submitHandler}
                            >
                                Submit
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
